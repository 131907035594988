import React from 'react'
import Layout from "../../../components/App/Layout"
import Navbar from "../../../components/App/Navbar"
import ContentBanner from '../../../components/Common/ContentBanner'
import Footer from "../../../components/App/Footer"
import ExampleDetail from '../../../components/Examples/ExampleDetail'
import ProjectStartArea from '../../../components/Common/StartProject';
import SEO from '../../../components/App/SEO';
import exampleMetaImage from '../../../assets/images/examples/widgets/instagram-follow-repost.png';
import Constants from '../../../constatns'
import PlatformExample from '../../../components/Examples/PlarformExample';

// Functional component
const Examples = () => {

  return (
	<Layout>
		<SEO
		  title="Instagramキャンペーンを拡散"
		  desc="このサンプルはInstagramを拡散させる方法です。InstagramはTwitterより拡散しにくい特徴がありキャンペーンを多くの人に知ってもらうことは簡単ではありません。リタッチのリツイート・いいねやシェア応募で拡散させることができます。"
		  banner={exampleMetaImage}
		  pathname={'/examples/instagram/follow-repost'}
		  type={'Article'}
		  shortName="Instagramaフォロー&リポストキャンペーン"
		  createdAt="2021-12-01"
		/>
	  <Navbar />
	  <ContentBanner
		homePageText="Home"
		homePageUrl="/"
		activePageText="Instagramキャンペーンを拡散"
		subDirectories={[{
		  url: '/examples',
		  name: '事例・サンプル'
		},{
		  url: '/examples/instagram',
		  name: 'Instagramキャンペーン'
		}]}
	  />
	  <ExampleDetail
		title={"Instagramキャンペーンを拡散"}
		description={<>このサンプルはInstagramを拡散させる方法です。<br/>InstagramはTwitterより拡散しにくい特徴がありキャンペーンを多くの人に知ってもらうことは簡単ではありません。<br/>リタッチのリツイート・いいねやシェア応募で拡散力があるInstagramキャンペーンを行うことが可能です。</>}
		effects={[Constants.CAMPAIGN_EFFECTS.GET_EMAILS,Constants.CAMPAIGN_EFFECTS.GET_SNS_ACCOUNTS, Constants.CAMPAIGN_EFFECTS.GET_FOLLOWERS
		  , Constants.CAMPAIGN_EFFECTS.GET_HIGH_SPREAD, Constants.CAMPAIGN_EFFECTS.GAIN_RECOGNITION]}
		plan={'ベーシックプラン以上'}
		promotionId="3a968664c9cd9ddd1a"
		platformExample={
		  <PlatformExample
			title="その他のInstagramキャンペーン"
			types={['instagramFollowLike', 'instagramFollowRepost', 'instagramFollowHashtag', 'password', 'instagramPhotoContest']}
		  />
		}
	  />
	  <ProjectStartArea />
	  <Footer />
	</Layout>
  );
}

export default Examples